import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

// components
import Layout from '../components/Layout';
import Homepage from '../components/Pages/Homepage';

// hooks
import useTranslated from '../hooks/useTranslated';

const IndexPage = ({ data, ...rest }) => {
    const { translatedData } = useTranslated(data, rest.pageContext);
    return (
        <Layout
            path={rest.location.pathname}
            lng={rest.pageContext.langKey}
            hasContainer={false}
            footerSections={data.strapiHomepage.subsections}
            metatags={translatedData.strapiHomepage.metatags}
            href="/"
        >
            <Homepage data={translatedData.strapiHomepage} lng={rest.pageContext.langKey} />
        </Layout>
    );
};

IndexPage.propTypes = {
    data: PropTypes.shape({
        strapiHomepage: PropTypes.shape({
            subsections: PropTypes.arrayOf(PropTypes.shape({})),
        }).isRequired,
    }).isRequired,
};

export default IndexPage;

export const query = graphql`
    query HomepageDE {
        strapiHomepage {
            metatags {
                description_de
                description_en
                title_de
                title_en
            }
            id
            title_en
            title_de
            background {
                publicURL
            }
            button {
                href
                label_en
                label_de
            }
            sections {
                button {
                    href
                    label_en
                    label_de
                }
                description_en
                description_de
                title_en
                title_de
                icon {
                    publicURL
                }
                image_de {
                    publicURL
                }
                image_en {
                    publicURL
                }
                imageSmall_de {
                    publicURL
                }
                imageSmall_en {
                    publicURL
                }
                id
            }
            subsections {
                description_en
                description_de
                image {
                    publicURL
                }
                title_en
                title_de
                background {
                    publicURL
                }
                id
            }
            chartData {
                value {
                    dateTime
                    title_de
                    title_en
                    valueType_de
                    valueType_en
                    mainValue
                    id
                }
            }
        }
    }
`;
